import React from 'react';
import PT from 'prop-types';

import logoLight from 'src/_images/logo/acf_white.svg';
import logoDark from 'src/_images/logo/acf_dark.svg';

import PageLink from 'src/components/navigation/PageLink';

const logoByType = {
  light: logoLight,
  dark: logoDark,
};

const svgStyle = {
  maxWidth: '100%',
  maxHeight: '100%',
  height: '100%',
};

export default function Logo(props) {
  const { type = 'dark', ...pass } = props;
  const theSrc = logoByType[type] || logoByType.dark;

  return (
    <PageLink page="home" {...pass}>
      <img style={svgStyle} src={theSrc} alt="Logo" />
    </PageLink>
  );
}

Logo.propTypes = {
  type: PT.oneOf(['light', 'dark']),
};
