import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocalization } from 'src/providers/LocalizationProvider';
import Box from '@material-ui/core/Box';
import Typography from 'src/components/Typography';
import AttributeTag from 'src/components/project/AttributeTag';
import CountryDetailsDataGroup from 'src/components/project/CountryDetailsDataGroup';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  numbers: {},
  dataGroup: {
    marginBottom: theme.spacing(2),
  },
  totals: {
    fontSize: 25,
  },
  crops: {},
}));

export default function CountryDetails(props) {
  const classes = useStyles();
  const { t, formatNumber } = useLocalization();
  const {
    alternateCrops,
    hectares,
    description,
    farmerDataByEmployment,
    farmerDataTotals,
  } = props;

  return (
    <Box className={classes.root}>
      {description ? (
        <div className={classes.dataGroup}>
          <Typography variant="body1">{description}</Typography>
        </div>
      ) : null}
      <div className={classes.dataGroup}>
        {farmerDataTotals.total ? (
          <Typography variant="body1" className={classes.totals}>
            {formatNumber(farmerDataTotals.total)} {t('projects.members')}
          </Typography>
        ) : null}
        {hectares ? (
          <Typography variant="body1" className={classes.totals}>
            {formatNumber(hectares)} {t('projects.hectares')}
          </Typography>
        ) : null}
      </div>
      <Box className={classes.numbers}>
        {farmerDataByEmployment &&
          Object.keys(farmerDataByEmployment).map(key => (
            <CountryDetailsDataGroup
              key={key}
              data={farmerDataByEmployment[key]}
            />
          ))}
      </Box>
      {alternateCrops && alternateCrops.length ? (
        <Box className={classes.crops}>
          <Typography variant="h6">{t('projects.alternateCrops')}</Typography>
          {alternateCrops.map((crop, idx) => (
            <AttributeTag key={idx}>{crop.title}</AttributeTag>
          ))}
        </Box>
      ) : null}
    </Box>
  );
}
