import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import thisIsAfrica from 'src/topojson/africa-iso3-manual.json';

import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';

const useStyles = makeStyles(theme => ({
  root: {
    // width: 640,
  },
}));

export default function GeoMap({
  onSelectCountry,
  countries,
  byCountry,
  activeCountry,
  isInteractive = true,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const handleClick = (geo, evt) => {
    if (!isInteractive) {
      return;
    }
    onSelectCountry(geo.properties.iso3);
  };

  const getDefaultStyles = ({ empty, selected }) => ({
    fill: selected
      ? theme.palette.map.selected
      : empty
      ? theme.palette.map.empty
      : theme.palette.map.active,
    stroke: theme.palette.map.selected,
    strokeWidth: 0.5,
    outline: 'none',
  });

  const createGetStyles = isInteractive => ({ empty, selected }) => ({
    default: getDefaultStyles({ empty, selected }),
    hover: isInteractive
      ? {
          stroke: theme.palette.map.selected,
          strokeWidth: 0.5,
          outline: 'none',
          cursor: empty ? 'default' : 'pointer',
          fill: empty ? theme.palette.map.empty : theme.palette.map.selected,
        }
      : getDefaultStyles({ empty, selected }),
    pressed: isInteractive
      ? {
          stroke: theme.palette.map.selected,
          strokeWidth: 0.5,
          outline: 'none',
          fill: empty ? theme.palette.map.empty : theme.palette.map.selected,
        }
      : getDefaultStyles({ empty, selected }),
  });

  const getStyles = createGetStyles(isInteractive);

  return (
    <Box className={classes.root}>
      <ComposableMap
        key="the-map"
        width="600"
        height="600"
        projectionConfig={{ scale: 250 }}
        style={{ width: '100%', minWidth: '100%' }}
      >
        <ZoomableGroup disablePanning center={[14, 0]} zoom={2}>
          <Geographies disableOptimization geography={thisIsAfrica}>
            {(geographies, projection) =>
              geographies.map((geo, i) => {
                const { iso3 } = geo.properties;
                const data = byCountry[iso3] || {};
                const hasMembers = data.farmerData && data.farmerData.length;

                const geoStyles = getStyles({
                  empty: !hasMembers,
                  selected: activeCountry === iso3,
                });

                const geoProps = {
                  key: `geo-${geo.properties.iso3}-${i}`,
                  cacheId: `geo-${geo.properties.iso3}-${i}`,
                  geography: geo,
                  projection: projection,
                  onClick: handleClick,
                  style: geoStyles,
                };

                return <Geography {...geoProps} />;
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </Box>
  );
}
