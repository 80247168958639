import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';

import Typography from 'src/components/Typography';

const useStyles = makeStyles(theme => ({
  container: ({ classes }) => ({
    position: 'relative',
    // textAlign: 'left',
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
    ...(classes && classes.container),
  }),
  text: ({ classes }) => ({
    ...(classes && classes.text),
  }),
  headline: ({ classes }) => ({
    color: theme.palette.primary.light,
    textTransform: 'uppercase',
    fontFamily: theme.typography.fontFamilySecondary,
    marginBottom: theme.spacing(2),
    ...(classes && classes.headline),
  }),
}));

export default function SectionHeadline({
  className,
  title,
  children,
  ...props
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ ...props, isMobile });

  return (
    <Container maxWidth="md">
      <Box className={clsx(classes.container, className)} {...props}>
        {title && (
          <Typography className={classes.headline} variant="h2">
            {title}
          </Typography>
        )}
        {children && (
          <Typography className={classes.text} variant="h5" component="div">
            {children}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

SectionHeadline.propTypes = {
  title: PT.string,
  className: PT.string,
  classes: PT.object,
};
