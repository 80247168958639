import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MetaNavigation from 'src/components/navigation/MetaNavigation';
import LanguageSwitcher from 'src/components/navigation/LanguageSwitcher';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  navigation: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  navigationLink: {
    color: theme.palette.grey['900'],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  navigationLinkActive: {
    color: theme.palette.primary.main,
  },
  languageSwitch: {
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));

export default function FooterNavigation(props) {
  const { onClickLink = x => x } = props;
  const styles = useStyles();
  const theme = useTheme();
  const { classes = {} } = props;

  return (
    <Grid
      className={styles.navigation}
      container
      alignItems="flex-start"
      justify="space-between"
      spacing={0}
    >
      <Grid item xs={12} md={9}>
        <Box display="flex" justifyContent="flex-start">
          <MetaNavigation
            linkProps={{ onClick: onClickLink }}
            classes={{
              button: clsx(styles.navigationLink, classes.navLink),
              active: clsx(styles.navigationLinkActive, classes.navLinkActive),
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <Box display="flex" className={styles.languageSwitch}>
          <LanguageSwitcher
            classes={{
              button: clsx(styles.navigationLink, classes.navLink),
              active: clsx(styles.navigationLinkActive, classes.navLinkActive),
            }}
            color={theme.palette.grey[500]}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
