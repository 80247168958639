import React from 'react';
import { Link } from 'gatsby';
import { useLocalization } from 'src/providers/LocalizationProvider';

const PageLink = ({ page, children, ...props }) => {
  const { pages } = useLocalization();
  const pageObj = pages[page];
  return (
    <Link {...props} to={pageObj.path}>
      {children}
    </Link>
  );
};

export default PageLink;
