import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ContentWrapper from 'src/components/content/ContentWrapper';
import React from 'react';
export default {
  Box,
  Typography,
  Container,
  ContentWrapper,
  React
};