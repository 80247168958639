import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from 'src/components/Typography';
import CountryDetails from 'src/components/project/CountryDetails';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';

const ExpansionPanel = withStyles({
  root: {
    color: '#fff',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpandMoreIcon = withStyles({
  root: {
    color: '#fff',
  },
})(MuiExpandMoreIcon);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default function CountryExpansionPanel({
  country,
  isActive,
  activeCountry,
}) {
  const classes = useStyles();

  const { countryName, countryIso3 } = country;

  return (
    <Box className={classes.root}>
      <ExpansionPanel square>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls={`country-details-${countryIso3}`}
          id={`country-header-${countryIso3}`}
        >
          <Typography variant="h4" className={classes.summary}>
            {countryName}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails id={`country-details-${countryIso3}`}>
          <CountryDetails {...country} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
}
