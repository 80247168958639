import React from 'react';
import acf from 'src/themes/acf';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createGlobalStyle } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme(acf);

const RootLayout = ({ children }) => {
  const GlobalStyles = createGlobalStyle`
    a[href] { text-decoration: none; }
    p { margin-top: 0; }
    img { max-width: 100%; }
    p a[href^="http:"],
    p a[href^="https:"],
    p a[href^="mailto:"] {
      color: inherit;
      text-decoration: underline;
    }
    p a[href^="http:"]:hover,
    p a[href^="https:"]:hover,
    p a[href^="mailto:"]:hover {
      text-decoration: none;
    }
    main, picture {
      display: block;
    }
  `;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default RootLayout;
