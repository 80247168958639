import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowRightIcon(props) {
  return (
    <SvgIcon {...props}>
      <polygon points="15,6.3 13.6,7.7 17.2,11.3 3,11.3 3,13.3 17.2,13.3 13.6,16.9 15,18.3 21,12.3 " />
    </SvgIcon>
  );
}
