import React, { useState } from 'react';
import clsx from 'clsx';
import PT from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import BurgerIcon from '@material-ui/icons/MenuOutlined';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import ModalNavigation from 'src/components/navigation/ModalNavigation';

const useStyles = makeStyles(theme => ({
  root: ({ sticky }) => ({
    // position: 'sticky', // sticky ? 'fixed' : 'relative',
    position: 'fixed',
    zIndex: 20,
    width: '100%',
    top: 0,
  }),
  button: {
    position: 'absolute',
    top: 5,
    right: 5,
    borderRadius: 0,
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    // backgroundColor: theme.palette.common.faded,
    [theme.breakpoints.up('md')]: {
      top: 10,
      right: 10,
    },
  },
  icon: {
    color: theme.palette.primary.main,
    // color: theme.palette.common.white,
    background: 'transparent',
    '&:hover': {
      // color: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const RefModalNavigation = React.forwardRef((props, ref) => (
  <ModalNavigation {...props} innerRef={ref} />
));

export default function NavigationToggle(props) {
  const { sticky, active } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles({ sticky });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box className={classes.root}>
        <IconButton
          className={clsx(classes.button, { [classes.active]: active })}
          aria-label="open navigation"
          onClick={handleOpen}
        >
          <BurgerIcon className={classes.icon} />
        </IconButton>
      </Box>
      <Modal
        aria-label="main navigation"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        // closeAfterTransition
        BackdropComponent={Backdrop}
        disableEnforceFocus
        timeout={500}
        BackdropProps={{
          timeout: 500,
        }}
        {...props}
      >
        <RefModalNavigation open={open} onClose={handleClose} />
      </Modal>
    </>
  );
}

NavigationToggle.propTypes = {
  sticky: PT.bool,
  active: PT.bool,
};
