import Typography from 'src/components/Typography';
import TextImageAlign from 'src/components/content/TextImageAlign';
import FrontmatterImage from 'src/components/markdown/FrontmatterImage';
import InternalLinkButton from 'src/components/navigation/InternalLinkButton';
import SectionHeadline from 'src/components/content/SectionHeadline';
import React from 'react';
export default {
  Typography,
  TextImageAlign,
  FrontmatterImage,
  InternalLinkButton,
  SectionHeadline,
  React
};