import React from 'react';

const MemberResourcesLink = props => {
  const { children, ...linkProps } = props;

  return (
    <a
      href={`${process.env.GATSBY_MEMBER_RESOURCES}`}
      target="_blank"
      rel="noopener noreferrer"
      {...linkProps}
    >
      {children}
    </a>
  );
};

export default MemberResourcesLink;
