import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'src/components/Typography';

const useStyles = makeStyles(theme => ({
  tag: ({ variant }) => ({
    display: 'inline-block',
    border: `1px solid ${
      variant === 'light'
        ? theme.palette.common.white
        : theme.palette.common.black
    }`,
    padding: '0 5px',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }),
}));

export default function AttributeTag({ children, ...props }) {
  const { variant = 'light' } = props;
  const classes = useStyles({ variant });
  return (
    <Typography component="span" variant="body1" className={classes.tag}>
      {children}
    </Typography>
  );
}
