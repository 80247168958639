import urlify from 'urlify';

export const urlifyer = urlify.create({
  addEToUmlauts: true,
  szToSs: true,
  toLower: true,
  spaces: '-',
  nonPrintable: '-',
  trim: true,
});

export const transformNews = news => ({
  // ...news,
  title: news.title,
  teaser: news.teaser,
  content: news.textContent,
  slug: news.slug,
  id: news.slug,
  date: news.dateCreated,
  url: `/news#${news.slug}`,
  externalUrl: news.linkUrl,
  image: (news.imageFile || [])[0] || {}, // ChildImageSharp
  // imageUrl: ((news.imageFile || [])[0] || {}).url || false,
  linkAs: 'full',
});

export const transformTeamMember = member => ({
  ...member,
  fullName: `${member.firstName} ${member.lastName}`,
  imageUrl: ((member.imageFile || [])[0] || {}).url || false,
  category: (member.category[0] || {}).title || false,
  socialLinks: {
    ...(member.linkedInUrl && { linkedIn: member.linkedInUrl }),
  },
});

export const mdxSectionTransformer = section => ({
  ...section,
  title: section.frontmatter.title,
  slug: urlifyer(section.frontmatter.slug || section.frontmatter.title),
});
