import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';

import Footer from 'src/components/Footer';
import { useLocalization } from 'src/providers/LocalizationProvider';

import SEO from 'src/components/SEO';
import MarkdownProvider from 'src/providers/MarkdownProvider';
import SiteHeader from 'src/components/navigation/SiteHeader';

const useStyles = makeStyles(theme => ({
  main: {
    minHeight: '80vh',
  },
  footer: {},
}));

const Layout = ({ children, ...props }) => {
  const { locale, page } = useLocalization();
  const classes = useStyles();

  return (
    <MarkdownProvider locale={locale}>
      <SEO keywords={[]} lang={locale} />
      <Helmet>
        <title>{page.title}</title>
        <html lang={locale} />
        <meta charset="utf-8" />
        <link rel="canonical" href="" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Abhaya+Libre:600,800|Montserrat:400,700,800&display=swap&subset=latin"
          rel="stylesheet"
        />
      </Helmet>
      <div className={classes.main}>
        <SiteHeader />
        {children}
      </div>
      <footer className={classes.footer}>
        <Footer />
      </footer>
    </MarkdownProvider>
  );
};

export default Layout;
