import React, {
  useContext,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { navigate } from 'gatsby';
import i18n from 'src/i18n';

import { formatNumber } from 'src/lib/format';
import { createLocalizeLink, createFormatDate } from 'src/i18n/helpers';
import languages from 'src/config/languages';
import { useTranslation } from 'react-i18next';

const localizeLink = createLocalizeLink(languages);
const locales = Object.keys(languages);
const LocalizationContext = createContext();

function useLocalization() {
  const context = useContext(LocalizationContext);
  if (context === undefined) {
    throw new Error('useLocalization must be used within a Provider');
  }
  return context;
}

// updates i18n language to locale provided by gatsby build process
// and handles language switching

const LocalizationProvider = ({ children, location, context, ...props }) => {
  // console.log('ssr', context, locale);
  const { locale, navigation, pages } = context;
  const { pathname } = location;
  const { t } = useTranslation();
  const [page, setPage] = useState({ path: '' });

  // create helper to format dates
  const formatDate = useMemo(() => createFormatDate(locale), [locale]);

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps

  // sets the active page object depending on the location
  useEffect(() => {
    const foundKey =
      pages &&
      Object.keys(pages).find(key => {
        return pathname === pages[key].path;
      });

    if (foundKey) {
      setPage(pages[foundKey]);
    }
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  function changeLocale(lang) {
    if (locale === lang) {
      return;
    }
    i18n.changeLanguage(lang).then(() => {
      navigate(languages[lang].default ? '/' : languages[lang].basePath);
    });
  }

  return (
    <LocalizationContext.Provider
      value={{
        locale, // the current locale set by the rendering process
        locales, // all available locales in the project
        location, // the current location object (needs to be passed into the provider)
        languages, // all available language configurations (see src/config/languages.js)
        changeLocale, // helper function to change the locale on client side
        localizeLink, // helper to create a localized link
        formatDate, // helper to format a date respecting the current locale
        formatNumber,
        page, // current page object
        pages, // pages configuration prepared for the current locale (see src/config/pages.js)
        t, // translation helper from react-i18next
        navigation, // navigations prepared for the current locale (see src/config/navigation.js)
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export default LocalizationProvider;
export { useLocalization };
