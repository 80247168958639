import Box from '@material-ui/core/Box';
import Typography from 'src/components/Typography';
import TextImageAlign from 'src/components/content/TextImageAlign';
import FrontmatterImage from 'src/components/markdown/FrontmatterImage';
import SectionHeadline from 'src/components/content/SectionHeadline';
import AllCropsList from 'src/components/project/AllCropsList';
import ContentWrapper from 'src/components/content/ContentWrapper';
import React from 'react';
export default {
  Box,
  Typography,
  TextImageAlign,
  FrontmatterImage,
  SectionHeadline,
  AllCropsList,
  ContentWrapper,
  React
};