import React from 'react';
import { useMarkdown } from 'src/providers/MarkdownProvider';
import Image from 'gatsby-image';

export default function FrontmatterImage({ path, type = 'fluid', ...props }) {
  const { getImageByPath, imagesByPath } = useMarkdown();
  const source = getImageByPath(path);

  if (!source) return null;

  if (!source.childImageSharp && source.extension === 'svg') {
    return <img src={source.publicURL} alt="" {...props} />;
  }

  return type === 'fluid' ? (
    <Image fluid={source.childImageSharp.fluid} {...props} />
  ) : (
    <Image fixed={source.childImageSharp.fixed} {...props} />
  );
}
