import SectionHeadline from 'src/components/content/SectionHeadline';
import ContentTable from 'src/components/content/ContentTable';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Typography from 'src/components/Typography';
import React from 'react';
export default {
  SectionHeadline,
  ContentTable,
  TableCell,
  TableRow,
  Box,
  Typography,
  React
};