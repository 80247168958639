import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'classnames';
import Box from '@material-ui/core/Box';
import Typography from 'src/components/Typography';
import { Link } from 'gatsby';
import NavigationLink from 'src/components/navigation/NavigationLink';
import { useMarkdown } from 'src/providers/MarkdownProvider';
import { useLocalization } from 'src/providers/LocalizationProvider';

const useStyles = makeStyles(theme => ({
  mainEntry: {
    // display: 'block',
    flex: `0 1 100%`,
    [theme.breakpoints.up('lg')]: {
      flex: `0 0 auto`,
    },
  },
  pageSections: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(0.5),
    },
  },
  pageSectionLink: {
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 100%',
    },
  },
  mainLinkText: {
    fontFamily: theme.typography.secondaryFont,
    fontWeight: 800,
    fontSize: 22,
    color: theme.palette.common.white,
    borderBottom: '2px solid transparent',
    transition: `all ${theme.transitions.duration['short']} ${theme.transitions.easing['sharp']}`,
    '&:hover': {
      borderBottomColor: theme.palette.common.white,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 46,
    },
  },
  mainLinkTextNoHover: {
    '&:hover': {
      borderBottom: 'none',
    },
  },
  mainLinkTextActive: {
    borderBottomColor: theme.palette.common.white,
  },
  sectionLinkText: {
    '&:hover': {
      borderBottomColor: theme.palette.common.white,
    },
  },
  sectionLinkTextActive: {
    borderBottomColor: theme.palette.common.white,
  },
  pageNavigation: {
    display: 'block',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
}));

const SiteNavigation = props => {
  const { onNavigationClick, handleBrandChange, ...passProps } = props;
  const { navigation, location, t, pages } = useLocalization();
  const { getSectionsByPage, filterNavigatable } = useMarkdown();
  const classes = useStyles();

  const handleSectionClick = url => evt => {
    // window.location.href = url;
    onNavigationClick(evt);
  };

  return (
    <>
      {navigation.main.map(page => {
        const pageSections = (getSectionsByPage(page) || []).filter(
          filterNavigatable
        );

        return (
          <Box
            pb={2}
            className={classes.pageNavigation}
            key={page.id}
            onMouseEnter={handleBrandChange(page.id)}
            onMouseLeave={handleBrandChange(false)}
            {...passProps}
          >
            <Box className={classes.mainEntry}>
              <Link
                to={page.path}
                onClick={onNavigationClick}
                className={classes.mainLink}
              >
                <Typography
                  component="span"
                  className={clsx(classes.mainLinkText, {
                    [classes.mainLinkTextActive]: location.pathname.includes(
                      page.path
                    ),
                  })}
                >
                  {page.title}
                </Typography>
              </Link>
            </Box>
            <Box className={classes.pageSections}>
              {pageSections.map(sect => (
                <NavigationLink
                  key={sect.slug}
                  onClick={handleSectionClick(`${page.path}#${sect.slug}`)}
                  className={classes.pageSectionLink}
                  classes={{
                    label: classes.sectionLinkText,
                    labelActive: classes.sectionLinkTextActive,
                  }}
                  text={sect.title}
                  url={`${page.path}#${sect.slug}`}
                />
              ))}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default SiteNavigation;
