import React from 'react';
import { makeStyles } from '@material-ui/styles';

import NavigationToggle from 'src/components/navigation/NavigationToggle';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const SiteHeader = props => {
  const classes = useStyles();
  return (
    <>
      <NavigationToggle />
    </>
  );
};

export default SiteHeader;
