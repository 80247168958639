import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';

import { useLocalization } from 'src/providers/LocalizationProvider';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  link: {
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 100%',
    },
  },
  button: {
    textTransform: 'none',
    fontWeight: 400,
    minWidth: 0,
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
}));

export default function MetaNavigation(props) {
  const { classes = {}, linkProps } = props;
  const { navigation = { meta: [], main: [] }, location } = useLocalization();
  const styles = useStyles();

  return (
    <Box className={styles.root} py={[2, 0]}>
      {navigation.meta.map(page => (
        <Link
          to={page.path}
          className={clsx(styles.link, classes.link)}
          key={page.id}
          {...linkProps}
        >
          <Button
            className={clsx(styles.button, classes.button, {
              [styles.active]: location.pathname.includes(page.path),
              [classes.active]: location.pathname.includes(page.path),
            })}
            variant="text"
            size="small"
          >
            {page.title}
          </Button>
        </Link>
      ))}
    </Box>
  );
}
