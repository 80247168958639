// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-default-js": () => import("./../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-foundation-js": () => import("./../src/templates/foundation.js" /* webpackChunkName: "component---src-templates-foundation-js" */),
  "component---src-templates-cotton-js": () => import("./../src/templates/cotton.js" /* webpackChunkName: "component---src-templates-cotton-js" */),
  "component---src-templates-membership-js": () => import("./../src/templates/membership.js" /* webpackChunkName: "component---src-templates-membership-js" */),
  "component---src-templates-resources-js": () => import("./../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-downloads-js": () => import("./../src/templates/downloads.js" /* webpackChunkName: "component---src-templates-downloads-js" */)
}

