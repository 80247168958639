import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  image: {
    width: '100%',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    flexBasis: '50%',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '25%',
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '12.5%',
    },
  },
}));

const LogoWall = () => {
  const classes = useStyles();

  const {
    craft: { entries },
  } = useStaticQuery(
    graphql`
      query LogoQuery {
        craft {
          entries(
            section: "partners"
            site: "en"
            orderBy: "dateCreated DESC"
          ) {
            id
            title
            ...partnerData
          }
        }
      }
    `
  );

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="stretch"
      >
        {entries.map((partner, idx) => {
          const image = partner.imageFile[0];
          return (
            <Grid className={classes.item} key={idx} item>
              <Image
                className={classes.image}
                objectFit="cover"
                objectPosition="right 50%"
                alt={image.title}
                fluid={image.imageAssetFile.childImageSharp.fluid}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default LogoWall;
