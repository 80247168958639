import React from 'react';
import Box from '@material-ui/core/Box';
import AttributeTag from 'src/components/project/AttributeTag';
import { useData } from 'src/providers/DataProvider';

const AllCropsList = props => {
  const { ...passProps } = props;
  const { getCategories } = useData();
  const categories = getCategories('crops') || [];
  return (
    <Box>
      {categories.map((crop, idx) => (
        <AttributeTag {...passProps} key={idx}>
          {crop.title}
        </AttributeTag>
      ))}
    </Box>
  );
};

export default AllCropsList;
