import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

import Typography from 'src/components/Typography';
// import Loader from 'src/components/Loader';
import CountryDetails from 'src/components/project/CountryDetails';
import TotalData from 'src/components/project/TotalData';
import GeoMap from 'src/components/project/GeoMap';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minHeight: '700px',
    },
  },
  map: {
    flex: '1 1 50%',
    width: '50%',
  },
  entry: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  details: {
    flex: '1 1 50%',
  },
  detailsContent: {
    position: 'relative',
  },
}));

export default function ProjectsMapView({
  introText,
  isInteractive,
  countries,
  byCountry,
  totalStats,
  activeCountry,
  onSelectCountry,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root} display="flex">
      <Box className={classes.map}>
        <GeoMap
          isInteractive={isInteractive}
          onSelectCountry={onSelectCountry}
          countries={countries}
          byCountry={byCountry}
          activeCountry={activeCountry}
        />
      </Box>
      <Box className={classes.details} p={[0, 1, 2]}>
        <Box className={classes.detailsContent}>
          <Fade in={!activeCountry} exit={!!activeCountry}>
            <Box className={classes.entry}>
              <TotalData {...totalStats} introText={introText} />
            </Box>
          </Fade>
          {countries.map(country => {
            const { countryName, countryIso3 } = country;
            const isCurrentCountry =
              activeCountry && countryIso3 === activeCountry;

            return (
              <Fade
                key={countryIso3}
                in={isCurrentCountry}
                exit={!isCurrentCountry}
              >
                <Box className={classes.entry}>
                  <Typography variant="h4" className={classes.summary}>
                    {countryName}
                  </Typography>
                  <CountryDetails {...country} />
                </Box>
              </Fade>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
