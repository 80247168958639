import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useLocalization } from 'src/providers/LocalizationProvider';

import Box from '@material-ui/core/Box';
import Typography from 'src/components/Typography';
import PieChart from 'react-minimal-pie-chart';

const CHART_SIZE = 80;

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  female: {
    color: theme.palette.map.female,
  },
  male: {
    color: theme.palette.map.male,
  },
  details: {},
  graph: {
    width: CHART_SIZE + 10,
    marginRight: theme.spacing(2),
  },
  piechart: {
    width: CHART_SIZE,
    height: CHART_SIZE,
  },
}));

export default function CountryDetailsDataGroup({ data }) {
  const { employmentType, numbers } = data;
  const { t, formatNumber } = useLocalization();
  const classes = useStyles();
  const theme = useTheme();

  if (!(employmentType && employmentType.slug)) return null;

  const maleKey = `projects.${employmentType.slug}-male`;
  const femaleKey = `projects.${employmentType.slug}-female`;
  const employmentText = t(`projects.${employmentType.slug}`);

  const maleText = t(maleKey);
  const femaleText = t(femaleKey);

  const hasStatistics =
    numbers.total > 0 && (numbers.male > 0 || numbers.female > 0);

  const chartData = [
    {
      title: femaleText,
      value: numbers.female,
      color: theme.palette.map.female,
    },
    {
      title: maleText,
      value: numbers.male,
      color: theme.palette.map.male,
    },
  ];

  const chartProps = {
    data: chartData,
    animate: true,
    animationDuration: 500,
    animationEasing: 'ease-out',
    cx: 50,
    cy: 50,
    label: false,
    labelPosition: 50,
    lengthAngle: 360,
    lineWidth: 30,
    paddingAngle: 0,
    radius: CHART_SIZE / 2,
    rounded: false,
    startAngle: 270,
    viewBoxSize: [100, 100],
  };

  return (
    <Box className={classes.root} display="flex" alignItems="center">
      {hasStatistics ? (
        <Box className={classes.graph}>
          <PieChart className={classes.piechart} {...chartProps} />
        </Box>
      ) : null}
      <Box className={classes.detaila}>
        {numbers.total ? (
          <Typography variant="body1">
            {formatNumber(numbers.total)} {employmentText}
          </Typography>
        ) : null}
        {hasStatistics ? (
          <>
            <Typography className={classes.female} variant="body1">
              {formatNumber(numbers.female)} {femaleText}
            </Typography>
            <Typography className={classes.male} variant="body1">
              {formatNumber(numbers.male)} {maleText}
            </Typography>
          </>
        ) : null}
      </Box>
    </Box>
  );
}
