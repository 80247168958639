module.exports = {
  en: {
    locale: 'en',
    name: 'English',
    basePath: '/',
    default: true,
  },
  fr: {
    locale: 'fr',
    name: 'Français',
    basePath: '/fr/',
  },
  pt: {
    locale: 'pt',
    name: 'Portugues',
    basePath: '/pt/',
  },
};
