import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useCountryData from 'src/components/project/useCountryData';
import ProjectsMapView from 'src/components/project/ProjectsMapView';
import ProjectsListView from 'src/components/project/ProjectsListView';

const Projects = props => {
  const { initialCountry = null, isInteractive = true } = props;
  const [activeCountry, setActiveCountry] = useState(initialCountry);
  const { countries, byCountry, totalStats } = useCountryData();

  const onSelectCountry = iso => {
    if (!iso || !byCountry[iso] || !isInteractive) {
      return;
    }

    setActiveCountry(activeCountry !== iso ? iso : null);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const passProps = {
    ...props,
    isInteractive,
    countries,
    byCountry,
    totalStats,
    activeCountry,
    onSelectCountry,
  };

  return isMobile ? (
    <ProjectsListView {...passProps} />
  ) : (
    <ProjectsMapView {...passProps} />
  );
};

export default Projects;
