import React from 'react';
import { useLocalization } from 'src/providers/LocalizationProvider';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from 'src/components/Typography';

const useStyles = makeStyles(theme => ({
  container: {},
  details: {
    fontSize: 25,
  },
  title: {
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function TotalData({ members, countries, hectares, introText }) {
  const { t, formatNumber } = useLocalization();

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography className={classes.title} variant="h3">
        {t('projects.ourNetwork')}
      </Typography>
      <Typography className={classes.details} variant="body1">
        {formatNumber(members.total)} {t('projects.members')}
      </Typography>
      <Typography className={classes.details} variant="body1">
        {countries} {t('projects.countries')}
      </Typography>
      <Box pt={2} pb={4}>
        <Typography className={classes.description}>{introText}</Typography>
      </Box>
    </Box>
  );
}
