import React, {
  useContext,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const DataContext = createContext();

function useData() {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useData needs to be called inside a DataContext');
  }
  return context;
}

const DataProvider = ({ children, categories, entries, ...props }) => {
  const [_categories, setCategories] = useState(false);
  const [_entries, setEntries] = useState(false);

  useEffect(() => {
    if (!categories) return;
    const grouped = categories.reduce((acc, curr) => {
      const { groupHandle } = curr;
      acc[groupHandle] = (acc[groupHandle] || []).concat(curr);
      return acc;
    }, {});

    setCategories(grouped);
  }, [categories]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!entries) return;
    setEntries(entries);
  }, [entries]); // eslint-disable-line react-hooks/exhaustive-deps

  const values = {
    categories: _categories,
    getCategories: handle => _categories[handle],
    entries: _entries,
    getEntries: handle => _entries[handle],
  };

  return (
    <DataContext.Provider value={values}>
      {typeof children === 'function' ? children(values) : children}
    </DataContext.Provider>
  );
};

export default DataProvider;

export { useData };
