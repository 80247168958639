import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Divider from 'src/components/content/ContentDivider';
import ContentWrapper from 'src/components/content/ContentWrapper';
import Typography from 'src/components/Typography';
import SectionHeadline from 'src/components/content/SectionHeadline';
import React from 'react';
export default {
  Box,
  Grid,
  Container,
  Divider,
  ContentWrapper,
  Typography,
  SectionHeadline,
  React
};