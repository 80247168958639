import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
// import Grid from '@material-ui/core/Grid';
// import Typography from 'src/components/Typography';
// import SignupForm from 'src/components/forms/SignupForm';
// import { useTheme } from '@material-ui/core/styles';
import FooterNavigation from 'src/components/navigation/FooterNavigation';

import Section from 'cl-ui/components/Section';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
  },
  footerNavLink: {
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  footerNavLinkActive: {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  signupForm: {},
  navColor: {
    textTransform: 'uppercase',
    fontWeight: 400,
    minWidth: 0,
    color: theme.palette.grey[700],
  },
  nav: {
    borderTop: `1px solid ${theme.palette.common.white}`,
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Section pt={12} pb={8} className={classes.root}>
      <Container maxWidth="xl">
        {/* <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>
              Stay up & stay connected
            </Typography>
            <Typography variant="body1">
              Sign up for our monthly newsletter to receive updates concerning
              innovative solutions, breaking news and more.{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.signupForm}>
            <SignupForm
              label={t('labels.newsletterText')}
              url={t('labels.newsletterUrl')}
              submitText={t('labels.newsletterSubmit')}
            />
          </Grid>
        </Grid> */}
        <Box mt={8} className={classes.nav}>
          <FooterNavigation
            classes={{
              navLink: classes.footerNavLink,
              navLinkActive: classes.footerNavLinkActive,
            }}
          />
        </Box>
      </Container>
    </Section>
  );
}
