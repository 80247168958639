import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const ContentDivider = () => {
  const classes = useStyles();
  return <Divider classes={{ root: classes.root }} />;
};

export default ContentDivider;
