import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

import ArrowRight from 'src/components/icons/ArrowRightIcon';
import PageLink from 'src/components/navigation/PageLink';

const useStyles = makeStyles(theme => ({
  button: {},
}));

const InternalLinkButton = ({ page, children }) => {
  const classes = useStyles();
  return (
    <PageLink page={page}>
      <Button
        className={classes.button}
        startIcon={<ArrowRight color="primary" />}
        variant="text"
      >
        {children}
      </Button>
    </PageLink>
  );
};

export default InternalLinkButton;
