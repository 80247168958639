import React, { useContext, createContext } from 'react';

import useMarkdownSections from './useMarkdownSections';

const MarkdownContext = createContext();

function useMarkdown() {
  const context = useContext(MarkdownContext);
  if (context === undefined) {
    throw new Error('useMarkdown needs to be called inside a MarkdownProvider');
  }
  return context;
}

const MarkdownProvider = ({ children, locale, ...props }) => {
  const values = useMarkdownSections({ locale });

  return (
    <MarkdownContext.Provider value={values}>
      {typeof children === 'function' ? children(values) : children}
    </MarkdownContext.Provider>
  );
};

export default MarkdownProvider;

export { useMarkdown };
