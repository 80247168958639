import { useEffect, useState } from 'react';
import { useData } from 'src/providers/DataProvider';

const TotalsNullValue = {
  countries: 0,
  members: { male: 0, female: 0, total: 0 },
  hectares: 0,
};

const useCountryData = () => {
  const [byCountry, setByCountry] = useState({});
  const [countries, setCountries] = useState([]);
  const [totalStats, setTotalStats] = useState(TotalsNullValue);

  const { entries } = useData();

  const countryData = entries['countryData'] || [];

  function normalizeValues(country) {
    country.farmerDataByEmployment = country.farmerData
      .filter(data => data.employmentType && data.employmentType.length)
      .reduce((acc, curr) => {
        const employmentKey = curr.employmentType[0].slug || 'unknown';

        let numbers = curr.numbers[0];

        numbers = {
          male: parseInt(numbers.male) || 0,
          female: parseInt(numbers.female) || 0,
          total: parseInt(numbers.total) || 0,
        };

        if ((numbers.female > 0 || numbers.male > 0) && !numbers.total) {
          numbers.total = numbers.male + numbers.female;
        }

        acc[employmentKey] = {
          employmentType: curr.employmentType[0],
          numbers,
        };

        return acc;
      }, {});

    country.farmerDataTotals =
      (country.farmerData.filter(data => !data.employmentType)[0] || {})
        .numbers[0] || null;

    return country;
  }

  function totalStatsReducer(acc, country) {
    const ctyTotals = (country.farmerData || []).find(
      entry => entry.numbers && !entry.employmentType
    ).numbers[0];

    return {
      countries: acc.countries + 1,
      members: {
        male: acc.members.male + parseInt(ctyTotals.male),
        female: acc.members.female + parseInt(ctyTotals.female),
        total: acc.members.total + parseInt(ctyTotals.total),
      },
      hectares: acc.hectares + country.hectares,
    };
  }

  useEffect(() => {
    const values = countryData.map(normalizeValues);

    const valuesByCty = values.reduce((obj, country) => {
      obj[country.countryIso3] = country;
      return obj;
    }, {});

    const totals = values.reduce(totalStatsReducer, { ...TotalsNullValue });

    setTotalStats(totals);
    setCountries(values);
    setByCountry(valuesByCty);
  }, [countryData]);

  return { countries, byCountry, totalStats };
};

export default useCountryData;
