import numeral from 'numeral';

numeral.register('locale', 'us', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: '€',
  },
});

numeral.locale('us');

export function formatNumber(value) {
  const ft = numeral(value).format('0,0');
  return ft;
}
