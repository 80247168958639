import React from 'react';
import MuiTypography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import PT from 'prop-types';

export const defaultVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'caption',
  'button',
  'overline',
  'srOnly',
  'inherit',
];

export const extendedVariants = ['navMenu', 'navLink'];

const styles = theme => ({
  textLink: theme.typography.textLink,
  navMenu: theme.typography.navMenu,
  navLink: theme.typography.navLink,
});

const Typography = props => {
  const { variant, classes, ...muiProps } = props;
  const isExtended = extendedVariants.includes(variant);

  return (
    <MuiTypography
      variant={isExtended ? 'inherit' : variant}
      className={classes[variant]}
      {...muiProps}
    />
  );
};

export const TypographyPropTypes = {
  variant: PT.oneOf(defaultVariants.concat(extendedVariants)),
};

Typography.propTypes = TypographyPropTypes;

export default withStyles(styles)(Typography);
