import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const ContentWrapper = props => {
  const { maxWidth, children, ...boxProps } = props;
  return (
    <Box {...boxProps}>
      <Container maxWidth={maxWidth}>{children}</Container>
    </Box>
  );
};

export default ContentWrapper;
