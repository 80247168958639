// import { useState, useEffect, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';

import { mdxSectionTransformer } from 'src/data/transformers';

const useMarkdownSections = ({ locale }) => {
  const {
    allMdx: { nodes },
  } = useStaticQuery(
    graphql`
      query {
        allMdx(sort: { fields: fileAbsolutePath, order: ASC }) {
          nodes {
            ...mdxSectionData
          }
        }
      }
    `
  );

  const filterNavigatable = node =>
    node.frontmatter && !node.frontmatter.skipNavigation;

  const byPage = node =>
    node.fileAbsolutePath.match(/_content\/(.*)\//i)[1] || 'unknown';

  const getImageByPath = path => imagesByPath[path];

  const filterByLocale = node => node.frontmatter.locale === locale;

  const relevantSections = nodes
    // .filter(filterNavigatable)
    .filter(filterByLocale)
    .map(mdxSectionTransformer);

  const newImages = uniqBy(
    flatten(nodes.map(node => node.frontmatter.images || [])),
    'relativePath'
  );

  const imagesByPath = newImages.reduce((acc, curr) => {
    acc[curr.relativePath] = curr;
    return acc;
  }, {});

  const sectionsByPage = groupBy(relevantSections, byPage);

  const getSectionsByPage = page =>
    sectionsByPage[page] ||
    sectionsByPage[page.id] ||
    sectionsByPage['404'] ||
    [];

  return {
    sections: relevantSections,
    sectionsByPage,
    getSectionsByPage,
    images: newImages,
    imagesByPath,
    getImageByPath,
    filterNavigatable,
  };
};

export default useMarkdownSections;
