import { createMuiTheme } from '@material-ui/core/styles';

export const secondaryFont = 'Montserrat, Helvetica, Arial, sans';
export const primaryColor = '#86d185';

export const config = {
  overrides: {
    MuiDivider: {
      root: {},
    },
    MuiTableCell: {
      head: {
        fontFamily: secondaryFont,
        fontSize: 16,
        fontWeight: 800,
      },
      body: {
        fontFamily: secondaryFont,
        fontSize: 16,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        textTransform: 'none',
      },
      label: {
        textDecoration: 'none',
      },
      outlinedPrimary: {
        borderWidth: 2,
        color: '#fff',
        fontWeight: 400,
        '&:hover': {
          borderWidth: 2,
          backgroundColor: primaryColor,
        },
        '&:active': {
          borderWidth: 2,
          backgroundColor: primaryColor,
        },
      },
      outlinedSecondary: {
        borderWidth: 2,
        color: '#000',
        borderColor: primaryColor,
        fontWeight: 400,
        '&:hover': {
          borderWidth: 2,
          borderColor: primaryColor,
          backgroundColor: primaryColor,
        },
        '&:active': {
          borderWidth: 2,
          borderColor: primaryColor,
          backgroundColor: primaryColor,
        },
      },
      containedPrimary: {
        backgroundColor: '#fff',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: primaryColor,
        boxShadow: 'none',
        color: '#000',
        '&:hover': {
          boxShadow: 'none',
          color: '#fff',
          backgroundColor: primaryColor,
        },
        '&:active': {
          boxShadow: 'none',
          color: '#fff',
          backgroundColor: primaryColor,
        },
      },
      containedSizeLarge: {
        padding: '2rem 3rem',
      },
      text: {
        fontWeight: 400,
        '&:hover': {
          background: 'transparent',
          textDecoration: 'none',
        },
      },
      textSizeSmall: {
        padding: '0.3rem 0.8rem',
        fontSize: 14,
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
    MuiTypography: {
      variantMapping: {
        navMenu: 'span',
        navLink: 'span',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 780,
      lg: 1020,
      xl: 1280,
      xxl: 1920,
    },
  },
  typography: {
    fontFamily: '"Abhaya Libre", Georgie, serif',
    fontFamilySecondary: secondaryFont,
    fontSize: 15,
    // spacing: 4,
    // spacing: factor => `${0.25 * factor}rem`, // (Bootstrap strategy)
    spacing: factor => [0, 4, 8, 16, 32, 64][factor],
    h1: {
      fontFamily: secondaryFont,
      fontWeight: 800,
      fontSize: 75,
      lineHeight: 1,
    },
    h2: {
      fontWeight: 800,
      fontSize: 50,
      lineHeight: 1.12,
    },
    h3: {
      fontWeight: 800,
      fontSize: 36,
      lineHeight: 1.17,
    },
    h4: {
      fontWeight: 800,
      fontSize: 30,
      lineHeight: 1.17,
    },
    h5: {
      fontWeight: 600,
      fontSize: 26,
      lineHeight: 1.17,
    },
    h6: {
      fontFamily: secondaryFont,
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.17,
      marginBottom: 10,
    },
    subtitle1: {
      fontFamily: secondaryFont,
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.44,
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: 25,
      fontWeight: 400,
      lineHeight: 1.36,
    },
    body1: {
      fontSize: 15,
      fontFamily: secondaryFont,
      fontWeight: 400,
      lineHeight: 1.55,
    },
    body2: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.25,
    },
    button: {
      fontFamily: secondaryFont,
      fontSize: 16,
      fontWeight: 700,
    },
    // caption
    // overline

    // *** additional variants ***
    navMenu: {
      fontFamily: secondaryFont,
      fontSize: 46,
      fontWeight: 800,
    },
    navLink: {
      fontFamily: secondaryFont,
    },
    textLink: {
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  palette: {
    linkedIn: '#0a66c2',
    map: {
      male: '#658fb3',
      female: '#9ab889',
      selected: '#86d185',
      active: '#26342d',
      empty: '#0e1e16',
    },
    brand: {
      foundation: '#658fb3',
      cotton: '#73a07b',
      membership: '#79aaaf',
    },
    primary: {
      // rgb(180, 198, 170);
      // light: '#b4c6aa',
      // light: '#ddead5',
      light: '#add097',
      main: primaryColor,
      dark: '#3b6449',
      contrastText: '#fff',
    },
    secondary: {
      main: '#0e1e16',
      dark: '#0e1e16',
      contrastText: '#fff',
    },
    common: {
      faded: 'rgba(0,0,0,0.075)',
      fadedLight: 'rgba(255,255,255,.1)',
    },
    background: {
      default: '#fff',
    },
  },
};

let theme = createMuiTheme(config);
theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down('md')]: {
    fontSize: 46,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
  },
};
theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('md')]: {
    fontSize: 40,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 24,
  },
};

// console.info('theme', theme);

export default theme;
