import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import PageLink from 'src/components/navigation/PageLink';
import ArrowRight from 'src/components/icons/ArrowRightIcon';
import ContentWrapper from 'src/components/content/ContentWrapper';
import React from 'react';
export default {
  Box,
  Typography,
  Container,
  Button,
  PageLink,
  ArrowRight,
  ContentWrapper,
  React
};