import { format, isDate, parseISO } from 'date-fns';
import { de, fr, pt, es, enUS } from 'date-fns/locale';

const localeMapping = {
  fr,
  de,
  es,
  pt,
  en: enUS,
};

export const createLocalizeLink = languages => (link, locale) => {
  const currentLang = languages[locale];
  let localizedPath = currentLang.default
    ? link
    : `${currentLang.basePath}${link}`;
  return localizedPath;
};

export const createFormatDate = currentLocale => (
  date,
  localeOverride = false,
  formatString = 'P'
) => {
  if (!date) return null;
  const dateValue = isDate(date) ? date : parseISO(date);
  const locale = localeOverride || currentLocale;
  return format(dateValue, formatString, { locale: localeMapping[locale] });
};
