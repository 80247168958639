import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PT from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import get from 'lodash/get';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

function generateBoxShadow({ align, focusColor }) {
  switch (align) {
    case 'right':
      return `-60px 0px 0px -40px ${focusColor}`;
    case 'left':
    default:
      return `60px 0px 0px -40px ${focusColor}`;
  }
}

const useStyles = makeStyles(theme => ({
  root: ({ classes }) => ({
    marginBottom: theme.spacing(2),
    ...(classes && classes.root),
  }),
  imageGrid: ({ classes, focusColor, align, isMobile }) => ({
    order: align === 'left' || isMobile ? 0 : 1,
    overflow: 'visible',
    flexBasis: '380px',
    boxShadow: isMobile ? 'none' : generateBoxShadow({ align, focusColor }),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  }),
  image: {
    height: '280px',
    width: 'auto',
    maxWidth: '380px',
  },
  content: ({ classes, align, isMobile }) => ({
    order: align === 'left' && !isMobile ? 1 : 0,
    alignSelf: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 20,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 40,
    },
    ...(classes && classes.content),
  }),
}));

export default function TextImageAlign(props) {
  const {
    className,
    focusColor = 'primary',
    image,
    align = 'left',
    children,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles({
    ...props,
    isMobile,
    focusColor: get(theme.palette, focusColor, theme.palette.primary),
  });

  const imageProps = {
    objectFit: 'cover',
    objectPosition: '50%',
    className: styles.image,
  };

  return (
    <Grid
      container
      alignContent="stretch"
      alignItems="center"
      className={clsx(styles.root, className)}
    >
      <Grid item xs={12} sm={4} className={styles.imageGrid}>
        {typeof image === 'string' ? (
          <img alt="" src={image} />
        ) : (
          image && React.cloneElement(image, { ...imageProps })
        )}
      </Grid>
      <Grid item xs={12} sm={8} className={styles.content}>
        <Box px={[0, 4, 12]}>{children}</Box>
      </Grid>
    </Grid>
  );
}

export const PropTypes = {
  image: PT.oneOfType([PT.string, PT.node]),
  focusColor: PT.string,
  align: PT.oneOf(['left', 'right']),
  children: PT.node,
  classe: PT.object,
  className: PT.string,
};

TextImageAlign.propTypes = PropTypes;
