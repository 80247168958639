import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 800,
  },
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
  },
}));

const ContentTable = props => {
  const { label, headers = [], children } = props;
  const styles = useStyles();
  return (
    <TableContainer>
      <Table className={styles.table} aria-label={label}>
        {headers.length && (
          <TableHead className={styles.head}>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody className={styles.body}>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContentTable;
