import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import GeoMap from 'src/components/project/GeoMap';

import TotalData from 'src/components/project/TotalData';
import CountryExpansionPanel from 'src/components/project/CountryExpansionPanel';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
  },
}));

export default function ProjectsListView({
  introText,
  activeCountry,
  countries,
  totalStats,
  byCountry,
  onSelectCountry,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.container} display="flex" flexDirection="column">
      <TotalData {...totalStats} introText={introText} />
      <Box>
        <GeoMap
          isInteractive={false}
          onSelectCountry={onSelectCountry}
          countries={countries}
          byCountry={byCountry}
          activeCountry={activeCountry}
        />
      </Box>
      {countries.map(country => {
        return (
          <CountryExpansionPanel
            activeCountry={activeCountry}
            key={country.countryIso3}
            country={country}
          />
        );
      })}
    </Box>
  );
}
