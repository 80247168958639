import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-xhr-backend';
import translations from 'src/config/locales/translations.json';
import languages from 'src/config/languages';
const langs = Object.keys(languages);

// const namespaces = Object.keys(translations);
const defaultLocale =
  Object.keys(languages).find(locale => languages[locale].default).locale ||
  'en';

i18n
  // .use(Backend)
  .use(initReactI18next)
  .init(
    {
      resources: {
        en: {
          translations: translations.en,
        },
        fr: {
          translations: translations.fr,
        },
        pt: {
          translations: translations.pt,
        },
      },
      lng: defaultLocale,
      fallbackLng: defaultLocale,
      ns: ['translations'],
      defaultNS: 'translations',
      debug: false,
      preload: langs,
      // initImmediate: false,
      interpolations: {
        escapeValue: false,
      },
      react: {
        // wait: true,
        useSuspense: false,
      },
    }
    // (err, done) => {
    //   // i18n.loadNamespac
    //   console.log('done', done);

    //   // Load translations into namespaces
    //   // loadBundles(translations);
    //   done();
    // }
  );

// function loadBundles(bundles) {
//   Object.keys(bundles).forEach(name => {
//     const bundle = bundles[name];
//     Object.keys(languages).forEach(locale => {
//       i18n.addResourceBundle(locale, name, bundle[locale]);
//     });
//   });
// }

// loadBundles(translations);

export default i18n;
