import React from 'react';
import LocalizationProvider from 'src/providers/LocalizationProvider';
import RootLayout from './RootLayout';
import { useSSR } from 'react-i18next';
import Layout from './Layout';

const ProvidedLayout = props => {
  const { location, pageContext: context } = props;
  // console.log(props);
  // useSSR(window.__initialI18nStore, context.locale);

  return (
    <RootLayout>
      <LocalizationProvider location={location} context={context}>
        <Layout {...props} />
      </LocalizationProvider>
    </RootLayout>
  );
};

export default ProvidedLayout;
