import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from 'src/components/Typography';
import ContentWrapper from 'src/components/content/ContentWrapper';
import MemberResourcesLink from 'src/components/content/MemberResourcesLink';
import SectionHeadline from 'src/components/content/SectionHeadline';
import ExternalLinkIcon from '@material-ui/icons/CallMadeOutlined';
import Divider from 'src/components/content/ContentDivider';
import React from 'react';
export default {
  Box,
  Button,
  Container,
  Typography,
  ContentWrapper,
  MemberResourcesLink,
  SectionHeadline,
  ExternalLinkIcon,
  Divider,
  React
};