import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  link: {
    // color: 'inherit',
    textTransform: 'none',
    fontWeight: 400,
    minWidth: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  label: {
    borderBottom: '1px solid transparent',
    transition: `all ${theme.transitions.duration['short']} ${theme.transitions.easing['sharp']}`,
    '&:hover': {
      borderBottomColor: theme.palette.primary.main,
    },
  },
  labelActive: {
    borderBottomColor: theme.palette.primary.main,
  },
}));

export default function NavigationLink(props) {
  const { url, text, isActive = false, classes = {}, ...linkProps } = props;

  const styles = useStyles();

  return (
    <a href={url} {...linkProps}>
      <Button
        variant="text"
        className={clsx(styles.link, classes.link)}
        classes={{
          label: clsx(styles.label, classes.label, {
            [styles.labelActive]: isActive,
            [classes.labelActive]: isActive,
          }),
        }}
      >
        {text}
      </Button>
    </a>
  );
}
