import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useLocalization } from 'src/providers/LocalizationProvider';

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'uppercase',
    fontWeight: 400,
    color: theme.palette.common.white,
    minWidth: 0,
    padding: '.5rem .75rem',
    '&:hover': {
      background: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
}));

const LanguageSwitcher = props => {
  const { color, classes = {} } = props;
  const { locale, locales, changeLocale } = useLocalization();
  const styles = useStyles({ color });

  return (
    <Box className={classes.root}>
      {locales.map(loc => (
        <Button
          className={clsx(styles.button, classes.button, {
            [styles.active]: loc === locale,
            [classes.active]: loc === locale,
          })}
          variant="text"
          size="small"
          key={loc}
          onClick={evt => changeLocale(loc)}
        >
          {loc}
        </Button>
      ))}
    </Box>
  );
};

export default LanguageSwitcher;
