import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/ClearOutlined';
import Box from '@material-ui/core/Box';
import FooterNavigation from 'src/components/navigation/FooterNavigation';
import Logo from 'src/components/Logo';
import Fade from '@material-ui/core/Fade';

import SiteNavigation from 'src/components/navigation/SiteNavigation';

const useStyles = makeStyles(theme => ({
  wrapper: ({ open }) => ({
    width: '100%',
    height: '100%',
  }),
  container: ({ activeBrand }) => ({
    width: '100%',
    height: '100%',
    backgroundColor:
      (activeBrand && theme.palette.brand[activeBrand]) ||
      theme.palette.primary.light,
    transition: `background 200ms ${theme.transitions.easing.sharp}`,
    padding: 0,
  }),
  toggle: {
    position: 'absolute',
    top: 5,
    right: 5,
    borderRadius: 0,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      top: 10,
      right: 10,
    },
  },
  logo: {
    marginTop: theme.spacing(1),
    height: 60,
    [theme.breakpoints.up('md')]: {
      height: 120,
    },
  },
  icon: {
    color: theme.palette.common.white,
    background: 'transparent',
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  main: {
    maxWidth: theme.breakpoints.values.lg,
  },
  meta: {
    borderTop: `1px solid ${theme.palette.common.white}`,
  },
  navigation: {
    height: '100%',
    overflowY: 'scroll',
  },
  footerNavLink: {
    color: theme.palette.grey['900'],
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  footerNavLinkActive: {
    color: theme.palette.common.white,
  },
}));

const ModalNavigation = props => {
  const { open, onClose, innerRef } = props;
  const [activeBrand, setActiveBrand] = useState(false);
  const classes = useStyles({ activeBrand, open });

  const handleNavigationClick = evt => {
    onClose();
  };

  const handleBrandChange = brand => evt => {
    setActiveBrand(brand);
  };

  return (
    <Fade in={open}>
      <div className={classes.wrapper} ref={innerRef}>
        <div className={classes.container}>
          <IconButton
            className={classes.toggle}
            aria-label="close navigation"
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
          <Box
            className={classes.navigation}
            px={[2, 4, 8]}
            py={[2]}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Box
              pt={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Logo className={classes.logo} type="light" />
            </Box>

            <Box className={classes.main} py={[2, 2, 0]}>
              <SiteNavigation
                handleBrandChange={handleBrandChange}
                onNavigationClick={handleNavigationClick}
              />
            </Box>

            <Box className={classes.meta}>
              <FooterNavigation
                onClickLink={handleNavigationClick}
                classes={{
                  navLink: classes.footerNavLink,
                  navLinkActive: classes.footerNavLinkActive,
                }}
              />
            </Box>
          </Box>
        </div>
      </div>
    </Fade>
  );
};

export default ModalNavigation;
